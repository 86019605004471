import {Injectable} from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService {

    constructor(private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('access_token') && localStorage.getItem('user')) {
            const rols = JSON.parse(localStorage.getItem('user')).my_roles;
            if (rols.includes('admin') || rols.includes('provider')) {
                return true;
            }
        }
        localStorage.removeItem('access_token');
        this.router.navigateByUrl('/pages/login');
        return false;
    }
}
